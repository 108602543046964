#site-footer {
  background: #111;
  color: #bbb;
  margin-top: 20px;

  .wrapper {
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    text-align: center;
  }

  .version {
    display: block;
    font-size: 8px;
    font-weight: bold;
    text-shadow: 2px 2px 3px #000;
  }

  .social-links {
    font-size: 25px;
    padding-bottom: 5px;
    padding-top: 20px;
  }

  .legals {
    font-size: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  #site-footer {
    .wrapper {
      line-height: 25px;
      text-align: left;
      padding: 25px 20px 10px;
    }

    .version {
      line-height: 10px;
    }

    .social-links {
      line-height: 35px;
      text-align: right;
    }

    .legals {
      text-align: right;
      padding: 0px 20px 10px;
    }
  }
}

#cookies-info-bar {
  background: #000;
  bottom: 0;
  box-shadow: 0 0 5px #000;
  left: 0;
  padding: 10px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 9999;

  .message {
    color: #eee;
    font-size: 12px;
    font-weight: bold;
  }

  .close {
    color: #fff;
    opacity: 0.5;

    &:hover {
      color: #fff;
      opacity: 0.9;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}
