@charset "utf-8";

$base-font-family: open_sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   14px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #00bd4d;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;


@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@import "00_bootstrap-theme",
"01_fonts",
"02_socicon",
"03_base",
"04_layout",
"05_syntax-highlighting";