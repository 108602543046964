.socicon {
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  top: 1px;
  position: relative;

  &:empty {
    width: 1em;
  }
}

.socicon-twitter:before { content: "a"; }
.socicon-facebook:before { content: "b"; }
.socicon-instagram:before { content: "x"; }
.socicon-github:before { content: "Q"; }
.socicon-rss:before { content: ","; }
.socicon-gitlab:before { content: "G"; font-family: 'gitlab-logo'; }
