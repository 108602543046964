a,
a:hover {
    color: #00b646;
}

.navbar-fixed-top {
	z-index: 3;
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.75s ease;
}

.navbar-inverse .navbar-toggle {
    border-color: #063;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #063;
}

.navbar-inverse .navbar-collapse {
  border-color: #063;
}

.navbar-inverse .navbar-nav > li > a:hover {
    background-color: #22cf5f;
}

.navbar-inverse {
    background-image: -webkit-linear-gradient(top, #00bd4d 0%, #00ad3d 100%);
    background-image: linear-gradient(to bottom, #00bd4d 0%, #00ad3d 100%);
    background-repeat: repeat-x;
    border-color: #00ad3d;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00bd4d', endColorstr='#ff00ad3d', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.9);
}

.navbar-inverse .navbar-nav > .active > a {
    background-image: -webkit-linear-gradient(top, #00ad3d 0%, #00af3f 100%);
    background-image: linear-gradient(to bottom, #00ad3d 0%, #00af3f 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ad3d', endColorstr='#ff00af3f', GradientType=0);
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 200, 250, 0.25);
box-shadow: inset 0 3px 9px rgba(0, 100, 150, 0.25);
}
