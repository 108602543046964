/**
 * Site wrapper
 */
#site-wrapper {
  padding-top: 51px;
}

.main-section {
  padding-top: 20px;

  h2 {
    border-bottom: 1px solid #ccc;
    color: #333;
    font-weight: bold;
    padding-bottom: 8px;
    text-shadow: 1px 1px 3px #999;
  }

  .continue {
    display: block;
    margin: 50px auto 0;
    padding: 0;
    position: relative;
    text-align: center;

    a {
      background-image: -webkit-linear-gradient(top, #00bd4d 0%, #00ad3d 100%);
      background-image: linear-gradient(to bottom, #00bd4d 0%, #00ad3d 100%);
      background-repeat: repeat-x;
      border-color: #00ad3d;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00bd4d', endColorstr='#ff00ad3d', GradientType=0);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      border: 1px solid #00AD3D;
      border-radius: 100%;
      bottom: -25px;
      box-shadow: 0 0 5px rgba(100, 100, 100, 0.9);
      display: block;
      height: 50px;
      left: calc(50% - 25px);
      position: absolute;
      width: 50px;
      z-index: 2;

      i {
        color: #fff;
        font-size: 30px;
        line-height: 50px;
        text-shadow: 0 2px 5px #444;
      }

      span {
        display: none;
      }
    }
  }
}

/**
 * Who am I?
 */
#who-am-i {
  background-color: #111;
  box-shadow: 0 0 5px rgba(100, 100, 100, 0.9);
  color: #ddd;
  font-size: 1.2em;
  line-height: 1.5em;
  padding-top: 40px;
  text-shadow: 2px 2px 2px #444;

  h1 {
    font-weight: bold;
  }

  .avatar {
    border: 1px solid #bbb;
    border-radius: 100%;
    box-shadow: 0 0 8px #444;
    height: 200px;
    width: 200px;
  }

  .social-links {
    margin-top: 20px;
    font-size: 35px;
  }
}

/**
 * Knowledge and experience
 */
.score-box {
  font-size: 13px;

  h4 {
    border-bottom: 1px solid #00ad3d;
    color: #00bd4d;
    font-size: 1.2em;
    font-weight: bold;
    padding-bottom: 5px;
    text-align: center;
  }

  dl {
    font-size: 0.95em;
  }

  dt {
    clear: both;
    float: left;
    width: 65%;
  }

  dd {
    display: none;
    float: left;

    &:before {
      content: "\2014";
      display: inline-block;
      margin: 0 5px;
    }
  }

  .gauge {
    background: #dfd;
    display: inline-block;
    height: 10px;
    position: relative;
    width: 35%;
  }

  .index {
    background: #00ad3d;
    display: inline-block;
    height: 6px;
    position: absolute;
    margin: 2px 0;
  }

  .label {
    background: #00ad3d;
    box-shadow: 0 0 8px #252;
    color: #fff;
    display: inline-block;
    left: -85px;
    margin-top: -3px;
    padding: 2px auto;
    position: absolute;
    z-index: 20;
    width: 80px;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s linear, opacity 0.5s linear;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}

/**
 * Experience timeline
 */
#experience-timeline {
  background: #333;
  box-shadow: 0 0 3px #333;
  color: #ddd;
}

.timeline {
  margin: 30px 0;
  text-align: justify;

  p, ul {
    font-size: 0.9em;
  }

  h3 {
    color: #00bc4c;
    font-weight: bold;
    font-size: 1.75em;
    margin: 40px auto;
    text-align: center;
  }

  h4 {
    border-bottom: 1px solid #dfd;
    color: #00bd4d;
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px -10px 5px;
    padding: 0 10px 5px;
    position: relative;

    span {
      display: block;
      font-weight: normal;
      font-size: 0.8em;
      padding-top: 2px;
    }

    .icon {
      color: $brand-color;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-size: 0.7em;

      -webkit-font-smoothing: antialiased;

      &.icon-certification:before { content: "\e007"; }
      &.icon-contract:before { content: "\e139"; }
      &.icon-event:before { content: "\e109"; }
    }
  }

}

@media (min-width: 992px) {
  .timeline {
    .entry {
      h4 {
        .icon {
          background: #dfd;
          border-radius: 20px;
          bottom: -15px;
          font-size: 1em;
          height: 30px;
          line-height: 1.75;
          position: absolute;
          text-align: center;
          width: 30px;
        }
      }

      &:nth-child(odd) {
        border-right: 5px solid #dfd;
        margin-left: 0;
        margin-right: calc(50% - 5px);
        padding-right: 23px;

        h4 {
          .icon {
            right: -30px;
          }
        }
      }

      &:nth-child(even) {
        border-left: 5px solid #dfd;
        margin-left: calc(50% - 5px);
        margin-right: 0;
        padding-left: 23px;

        h4 {
          .icon {
            left: -30px;
          }
        }
      }
    }
  }
}

/**
 * Projects
 */
#projects {
  h3 {
    border-bottom: 1px solid #00ad3d;
    color: #00bd4d;

    span {
      display: inline-block;
      font-size: 0.6em;
      float: right;
      margin-top: 10px;
    }
  }
}

#open-source-more {
  margin-top: 45px;
}

/**
 * Contact me
 */
#contact-me {
  dt {
    clear: both;
    display: block;
    float: left;
    line-height: 0.8em;
    margin: 5px 10px 5px 0;
    text-align: right;
    width: 80px;
  }

  dd {
    line-height: 1.5em;
    margin: 5px 10px 5px 0;
  }
}

#gpg-info {
  pre {
    background: none;
    border: none;
    display: inline;
    font-family: monospace;
    font-size: 1em;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
  }
}