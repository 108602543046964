@font-face {
  font-family: 'open_sans';
  src: url('/css/fonts/opensans/opensans-bold.eot');
  src: url('/css/fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'),
  url('/css/fonts/opensans/opensans-bold.woff2') format('woff2'),
  url('/css/fonts/opensans/opensans-bold.woff') format('woff'),
  url('/css/fonts/opensans/opensans-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans';
  src: url('/css/fonts/opensans/opensans-bolditalic.eot');
  src: url('/css/fonts/opensans/opensans-bolditalic.eot?#iefix') format('embedded-opentype'),
  url('/css/fonts/opensans/opensans-bolditalic.woff2') format('woff2'),
  url('/css/fonts/opensans/opensans-bolditalic.woff') format('woff'),
  url('/css/fonts/opensans/opensans-bolditalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'open_sans';
  src: url('/css/fonts/opensans/opensans-italic.eot');
  src: url('/css/fonts/opensans/opensans-italic.eot?#iefix') format('embedded-opentype'),
  url('/css/fonts/opensans/opensans-italic.woff2') format('woff2'),
  url('/css/fonts/opensans/opensans-italic.woff') format('woff'),
  url('/css/fonts/opensans/opensans-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'open_sans';
  src: url('/css/fonts/opensans/opensans-regular.eot');
  src: url('/css/fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'),
  url('/css/fonts/opensans/opensans-regular.woff2') format('woff2'),
  url('/css/fonts/opensans/opensans-regular.woff') format('woff'),
  url('/css/fonts/opensans/opensans-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'socicon';
  src: url('/css/fonts/socicon/socicon-webfont.eot');
  src: url('/css/fonts/socicon/socicon-webfont.eot?#iefix') format('embedded-opentype'),
  url('/css/fonts/socicon/socicon-webfont.woff') format('woff'),
  url('/css/fonts/socicon/socicon-webfont.ttf') format('truetype'),
  url('/css/fonts/socicon/socicon-webfont.svg#sociconregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gitlab-logo';
  src: url('/css/fonts/gitlab-logo/gitlab-logo.woff') format('woff'),
  url('/css/fonts/gitlab-logo/gitlab-logo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
