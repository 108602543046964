.social-links {
  a {
    color: #fff;
    display: inline-block;
    margin: 0 10px;
    text-align: center;
    text-shadow: 2px 2px 3px #000;
    transition: color 0.75s ease;

    &:hover {
      color: #22cf5f;
    }

    span {
      display: none;
    }
  }
}

@import "parts/01_index_page",
"parts/02_footer";